import { createApp } from "vue";
import App from "@/App.vue";
import { createPinia } from 'pinia'
import router from "@/router";
import {store} from "@/store";
import Toast from "vue-toastification";
require("@/assets/main.scss");
import "@/assets/toast.scss";
import { POSITION } from "vue-toastification";
const options = {
    // You can set your default options here
    position:POSITION.BOTTOM_RIGHT
};

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(far,fas) // add all solid icons

const pinia = createPinia()
createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(pinia)
.use(router)
.use(Toast,options)
.mount("#app");
