<template>
  <BulmaNavbar
    @logout="logout"
    :email="email"
    :isAdmin="email=='loonbrieven@loonburo.be'"
  />
  <section class="mt-6 section" v-if="isLoaded">
      <table class="table is-bordered is-fullwidth" v-if="!isAdmin">
        <thead>
          <tr class="has-background-primary">
            <th class="has-text-white">Jaar</th>
            <th class="has-text-white">Loonbrieven</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="has-text-left">
                <p v-for="year in Object.keys(structuredLoonbrieven).sort().reverse()" :key="year" @click="selectedYear=year" class="is-clickable" :class="{'has-text-weight-bold':lastYear==year}">
                  {{ year }}
                </p>
            </td>
            <td clas="has-text-left">
                <p v-for="loonbrief in structuredLoonbrieven[lastYear]" :key="loonbrief" @click="loadLoonbrief(loonbrief)" class="is-clickable  has-text-left">
                  <span class="icon"><font-awesome-icon icon="file-pdf" class="has-text-danger" /></span><span>{{ loonbrief }}</span>
                </p>
            </td>
          </tr>
        </tbody>
      </table>
    <div v-else>
      <div class="notification is-info">
        <b>Boodschap aan Lieven</b> : Mails worden elk uur verwerkt... automatisch :)
      </div>
      <div class="columns">
        <div class="column">
          <BulmaAdminTable
            :dataList="numberedUsers"
            :labels="['#','email','aantal','geregistreerd']"
            :columns="['number','email','count','isRegistered']"
            :widths="['15%','45%','20%','20%']"
            :filters="['email','count','isRegistered']"
            :actions="[]"
            identifier="email"
            size="is-size-7"
            :perPage="15"
            batchSize=0
          />
        </div>
        <div class="column" v-if="tracker.lastMail!=undefined">
          <table class="table is-bordered is-fullwidth">
            <thead>
              <tr class="has-background-primary">
                <th class="has-text-white is-size-7">Log - Laatste mail = {{ tracker.lastMail }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l,i in lastLogs" :key="'log'+i">
                <th
                  class="is-size-7 is-family-monospace"
                  :class="{'has-text-warning':l.includes('Geen'),'has-text-info':l.includes('Found'),'has-text-success':l.includes('Done')}"
                >{{ l }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";
import BulmaNavbar from "@/components/BulmaNavbar.vue"
import BulmaAdminTable from "@/components/BulmaAdminTable.vue"
// import BulmaButton from "@/components/BulmaButton.vue"
// import BulmaCheckRadio from "@/components/BulmaCheckRadio.vue"
import TokenStorage from "@/lib/TokenStorage"
import ErrorHandler from "@/lib/ErrorHandler"
import { useStore } from "@/store"
import { useToast } from "vue-toastification";
import {saveAs} from 'file-saver';

export default {
  name: "HomeView",
  components: {
    BulmaNavbar,
    BulmaAdminTable
  },
  setup() {
    const store = useStore()
    const toast = useToast()
    return {
      store,
      toast
    }
  },
  data() {
    return {
      email:undefined,
      loonbrieven:[],
      selectedYear: null,
      users:[],
      tracker:{},
      timeout:undefined
    };
  },
  computed: {

    isLoaded(){
      return true
    },
    isAdmin(){
      return this.email=='loonbrieven@loonburo.be'
    },
    lastLogs(){
      if(this.tracker.lastMail!=undefined){
        return this.tracker.log.slice(-20).map(x => x.toString())
      }else{
        return []
      }
    },
    numberedUsers(){
      return this.users.map((x,i) => {x.number=i+1;return x})
    },
    lastYear(){
      return this.selectedYear || Object.keys(this.structuredLoonbrieven).sort().reverse()[0]
    },
    structuredLoonbrieven(){
      // loop loonbrieven and extract year and make a dictionary
      var result={}
      this.loonbrieven.forEach(loonbrief => {
        var match=loonbrief.match(/^[^_]*_(\d{4}).*$/)
        if(match){
          var year=match[1]
          if(result[year]==undefined){
            result[year]=[]
          }
          result[year].push(loonbrief)
        }
      })
      return result
    }
  },
  methods: {
    loadUsers(){
      var ref=this;
      axios.get(`${this.store.server}/api/v1/users`,TokenStorage.getAuthentication())                               // check database
        .then((result)=>{
           this.users = result.data.users
        })
        .catch(function(err){
          ErrorHandler.toast(err)
        });
    },
    loadProcess(){
      var ref=this;
      axios.get(`${this.store.server}/api/v1/process`,TokenStorage.getAuthentication())                               // check database
        .then((result)=>{
           this.tracker = result.data.tracker
        })
        .catch(function(err){
          ErrorHandler.toast(err)
        });
    },
    repeatProcess(){
        this.loadProcess()
        this.timeout=setTimeout(this.repeatProcess, 5000);
    },
    loadLoonbrieven(){
      var ref=this;
      axios.get(`${this.store.server}/api/v1/loonbrieven`,TokenStorage.getAuthentication())                               // check database
        .then((result)=>{
           this.loonbrieven = result.data.pdfs
        })
        .catch(function(err){
          ErrorHandler.toast(err)
        });
    },
    loadLoonbrief(name){
      var ref=this;
      var options = TokenStorage.getAuthentication()
      options.responseType="blob"
      axios.get(`${this.store.server}/api/v1/loonbrieven/${name}`,options)                               // check database
        .then(response => {
          saveAs(response.data, name);
        })
        .catch(function(err){
          ErrorHandler.toast(err)
        });
    },
    logout(){
      var ref=this;
      TokenStorage.clear()
      ref.$router.replace({name:"login"}).catch(err => {});
    }

  },
  mounted() {
    this.loadLoonbrieven();
    this.email=TokenStorage.getPayload().email
    if(this.isAdmin){
      this.loadUsers();
      this.repeatProcess()
    }
  },
  beforeUnmount(){
    clearTimeout(this.timeout)
  }
};
</script>
<style lang="scss">
</style>
