<template>
      <router-view />
</template>
<script>
  export default {
    name: "App",
    data() {
      return {
      };
    },
    methods: {
    },
    mounted() {
    }
  };
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;
}
</style>
